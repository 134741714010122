<template>
  <svg
    :class="{
      'transform rotate-0': direction === 'down',
      'transform rotate-180': direction === 'up',
      'transform rotate-90': direction === 'right',
      'transform rotate-270': direction === 'left',
    }"
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      d="M4 4.2733L4.94 3.3333L8 6.38663L11.06 3.3333L12 4.2733L8 8.27329L4 4.2733Z"
      fill="currentColor"
    />
    <path
      d="M4 8.66663L4.94 7.72663L8 10.78L11.06 7.72663L12 8.66663L8 12.6666L4 8.66663Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcDoubleArrow',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
    direction: {
      type: String,
      default: 'down',
      validator: val => ['up', 'down', 'left', 'right'].includes(val),
    },
  },
}
</script>

<style scoped lang="scss"></style>
