<template>
  <bbBaseCard
    class="bg-white mt-4"
    :checkbox="false"
    :curtain="false"
    @cardClick="selectSite(site)"
  >
    <template #first>
      <div class="flex-col items-start gap-1 inline-flex min-w-xxs">
        <div class="text-bb-neutral-gray text-xs font-medium leading-5">Account</div>
        <div class="justify-start items-center gap-2 inline-flex py-5">
          <div
            class="cursor-pointer"
            @click.stop="toggleFavourite(site)"
          >
            <IcFavouriteOutline
              v-if="!site.starred"
              class="text-bb-disabled-buttons"
            />
            <IcFavourite
              v-if="site.starred"
              class="text-bb-decorative-yellow"
            />
          </div>

          <div class="text-gray-700 text-sm font-bold">{{ site.name }}</div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="flex items-start gap-x-10">
        <div class="card-item md:inline-flex w-14">
          <div class="md:text-left text-bb-neutral-gray text-xs font-medium leading-5">Autopilot</div>
          <div class="justify-center items-center gap-1 inline-flex my-auto">
            <div class="text-indigo-500 mt-4">
              <IcStars
                :class="{ 'text-bb-text-default': !site.autopilotStatus }"
                :size="24"
              />
            </div>
            <div class="mt-auto">{{ site.autopilotStatus ? 'On' : 'Off' }}</div>
          </div>
        </div>
        <div class="card-item md:inline-flex w-36">
          <div class="md:text-left text-bb-neutral-gray text-xs font-medium leading-5">Current/Target Spend</div>
          <div v-if="site.site_metric_target?.spend && site.cost?.current">
            <div
              class="justify-start items-center mt-1 my-auto w-36 truncate"
              :title="`${readableMetric(site.cost.current)}/${readableMetric(site.site_metric_target.spend)} ${site.currency}`"
            >
              <span
                class="font-bold text-bb-text-default"
                :class="progressBarLabelsColor(calculateSpendPercentage(site))"
                >{{ readableMetric(site.cost.current) }}</span
              >
              <span>/{{ readableMetric(site.site_metric_target.spend) }}</span>
              <span class="font-normal text-xs"> {{ site.currency }}</span>
            </div>
            <div class="mt-1 flex justify-end">
              <ProgressBar
                :class="progressBarLabelsColor(calculateSpendPercentage(site))"
                :value="calculateSpendPercentage(site)"
                :progress-mark="75"
                :custom-range="customRange"
                :custom-label-colors="true"
              ></ProgressBar>
            </div>
          </div>
          <div
            v-else
            class="mt-4"
          >
            <p>-</p>
          </div>
        </div>
        <div class="card-item md:inline-flex w-24">
          <div class="md:text-left text-bb-neutral-gray text-xs font-medium leading-5">Clicks</div>
          <div class="flex gap-1">
            <ic-double-arrow
              class="my-auto"
              :class="[
                getClassBasedOnChange(site.clicks.current, site.clicks.compare).rotateClass,
                getClassBasedOnChange(site.clicks.current, site.clicks.compare).colorClass,
              ]"
              :size="16"
            />
            <div
              class="md:text-left font-bold text-bb-text-default p-1 max-w-20 truncate"
              :title="readableMetric(site.clicks.current)"
            >
              {{ readableMetric(site.clicks.current) }}
            </div>
          </div>
          <div class="justify-end items-center gap-1 inline-flex font-medium text-xs">
            <p :class="getClassBasedOnChange(site.clicks.current, site.clicks.compare).colorClass">
              {{ calculatePercentageChange(site.clicks.current, site.clicks.compare) }}%
            </p>
          </div>
        </div>
        <div class="card-item md:inline-flex w-24">
          <div class="md:text-left text-bb-neutral-gray text-xs font-medium leading-5">Conversions</div>
          <div class="flex gap-1">
            <ic-double-arrow
              class="my-auto"
              :class="[
                getClassBasedOnChange(site.conversions.current, site.conversions.compare).rotateClass,
                getClassBasedOnChange(site.conversions.current, site.conversions.compare).colorClass,
              ]"
              :size="16"
            />
            <div
              class="md:text-left font-bold text-bb-text-default p-1 max-w-20 truncate"
              :title="readableMetric(site.conversions.current)"
            >
              {{ readableMetric(site.conversions.current) }}
            </div>
          </div>
          <div class="justify-end items-center gap-1 inline-flex text-xs">
            <div :class="getClassBasedOnChange(site.conversions.current, site.conversions.compare).colorClass">
              {{ calculatePercentageChange(site.conversions.current, site.conversions.compare) }}%
            </div>
          </div>
        </div>
        <div class="card-item md:inline-flex w-24">
          <div class="md:text-left text-bb-neutral-gray text-xs font-medium leading-5">Cost/Conv</div>
          <div
            v-if="!site.mixed_currencies"
            class="flex-col gap-1 flex text-bb-text-default"
          >
            <div class="flex gap-1">
              <ic-double-arrow
                class="my-auto"
                :class="[
                  getClassBasedOnChange(
                    site.cost.current / site.conversions.current,
                    site.cost.compare / site.conversions.compare,
                    'cost',
                  ).rotateClass,
                  getClassBasedOnChange(
                    site.cost.current / site.conversions.current,
                    site.cost.compare / site.conversions.compare,
                    'cost',
                  ).colorClass,
                ]"
                :size="16"
              />
              <div
                class="md:text-left items-center p-1 max-w-20 truncate"
                :title="`${calculateCostPerConversion(site.cost.current, site.conversions.current)} ${site.currency}`"
              >
                <span class="font-bold">{{
                  calculateCostPerConversion(site.cost.current, site.conversions.current)
                }}</span>
                <span class="font-normal text-xs"> {{ site.currency }}</span>
              </div>
            </div>
            <div class="justify-end items-center gap-1 inline-flex text-xs">
              <div
                :class="[
                  getClassBasedOnChange(
                    site.cost.current / site.conversions.current,
                    site.cost.compare / site.conversions.compare,
                    'cost',
                  ).colorClass,
                ]"
              >
                {{
                  calculateConversionCostPercentageChange(
                    site.cost.current,
                    site.conversions.current,
                    site.cost.compare,
                    site.conversions.compare,
                  )
                }}%
              </div>
            </div>
          </div>
          <div
            v-else
            class="my-auto"
          >
            <p>N/A</p>
          </div>
        </div>
        <div class="card-item md:inline-flex w-24">
          <div class="md:text-left text-bb-neutral-gray text-xs font-medium leading-5">Cost</div>
          <div
            v-if="!site.mixed_currencies"
            class="flex-col gap-1 flex text-bb-text-default"
          >
            <div class="flex flex-row gap-1">
              <ic-double-arrow
                class="my-auto"
                :class="[
                  getClassBasedOnChange(site.cost.current, site.cost.compare, 'cost').rotateClass,
                  getClassBasedOnChange(site.cost.current, site.cost.compare, 'cost').colorClass,
                ]"
                :size="16"
              />
              <div
                class="md:text-left items-center p-1 max-w-20 truncate"
                :title="`${readableMetric(site.cost.current)} ${site.currency}`"
              >
                <span class="font-bold">{{ readableMetric(site.cost.current) }}</span>
                <span class="font-normal text-xs"> {{ site.currency }}</span>
              </div>
            </div>
            <div class="justify-end items-center gap-1 inline-flex text-xs">
              <div :class="[getClassBasedOnChange(site.cost.current, site.cost.compare, 'cost').colorClass]">
                {{ calculatePercentageChange(site.cost.current, site.cost.compare) }}%
              </div>
            </div>
          </div>
          <div
            v-else
            class="my-auto"
          >
            <p>N/A</p>
          </div>
        </div>
      </div>
    </template>
  </bbBaseCard>
</template>

<script>
import bbBaseCard from '@/components/card/bbBaseCard.vue'
import IcFavouriteOutline from '@/components/icon/ic-favourite-outline.vue'
import IcFavourite from '@/components/icon/ic-favourite.vue'
import ProgressBar from '@/components/shared/ProgressBar.vue'
import IcDoubleArrow from '@/components/icon/brightbid/ic-double-arrow.vue'
import Toast from '@/components/shared/Toast.vue'
import {
  getClassBasedOnChange,
  calculatePercentageChange,
  calculateConversionCostPercentageChange,
  calculateCostPerConversion,
} from '@/utils/compute-metrics-class'
import IcStars from '@/components/icon/ic-stars'
import { mapActions, mapState } from 'vuex'

const orangeColor = '#FFA800'
const greenGradient = 'linear-gradient(93deg, #4FD99F 0%, #57FFC3 100%)'
const redGradient = 'linear-gradient(90deg, #F23F44 10.54%, #FF9191 92.15%)'

export default {
  components: {
    IcFavouriteOutline,
    IcFavourite,
    ProgressBar,
    IcStars,
    bbBaseCard,
    IcDoubleArrow,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
      isHovered: false,
      // Initialize caches for each method
      getClassBasedOnChangeCache: new Map(),
      calculatePercentageChangeCache: new Map(),
      readableMetricCache: new Map(),
      calculateConversionCostPercentageChangeCache: new Map(),
      calculateCostPerConversionCache: new Map(),
      customRange: {
        breakpoints: [85, 95, 106, 121],
        colors: [redGradient, orangeColor, greenGradient, orangeColor, redGradient],
      },
    }
  },
  computed: {
    ...mapState('organization', ['userOrganization']),
  },
  methods: {
    ...mapActions({
      loadSelectedSite: 'site/loadSelectedSite',
    }),
    memoizeMethod(cacheKey, cacheMap, fn) {
      if (cacheMap.has(cacheKey)) {
        return cacheMap.get(cacheKey)
      } else {
        const result = fn()
        cacheMap.set(cacheKey, result)
        return result
      }
    },
    getClassBasedOnChange(value, compareValue, metricType = null) {
      const cacheKey = `${value}-${compareValue}-${metricType}`
      return this.memoizeMethod(cacheKey, this.getClassBasedOnChangeCache, () =>
        getClassBasedOnChange(value, compareValue, metricType),
      )
    },
    calculatePercentageChange(current, compare) {
      const cacheKey = `${current}-${compare}`
      return this.memoizeMethod(cacheKey, this.calculatePercentageChangeCache, () =>
        calculatePercentageChange(current, compare),
      )
    },
    readableMetric(value) {
      const cacheKey = `${value}`
      const result = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return this.memoizeMethod(cacheKey, this.readableMetricCache, () => result)
    },
    calculateConversionCostPercentageChange(currentCost, currentConversions, compareCost, compareConversions) {
      const cacheKey = `${currentCost}-${currentConversions}-${compareCost}-${compareConversions}`
      return this.memoizeMethod(cacheKey, this.calculateConversionCostPercentageChangeCache, () =>
        calculateConversionCostPercentageChange(currentCost, currentConversions, compareCost, compareConversions),
      )
    },
    calculateCostPerConversion(cost, conversions) {
      const cacheKey = `${cost}-${conversions}`
      return this.memoizeMethod(cacheKey, this.calculateCostPerConversionCache, () =>
        calculateCostPerConversion(cost, conversions),
      )
    },
    async addAsFavourite(site) {
      try {
        await this.$http.put(`/common/site/${site.id}/star`)
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: `${site.name} added to favorites`,
            type: 'success',
          },
        })
        await this.updateSite(site)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to add organization to favorites',
            type: 'error',
          },
        })
      }
    },
    async removeFromFavourite(site) {
      try {
        await this.$http.delete(`/common/site/${site.id}/star`)
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: `${site.name} removed from favorites`,
            type: 'success',
          },
        })
        await this.updateSite(site)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to remove site from favorites',
            type: 'error',
          },
        })
      }
    },
    async toggleFavourite(site) {
      if (site.starred) {
        await this.removeFromFavourite(site)
      } else {
        await this.addAsFavourite(site)
      }
    },
    async updateSite(site) {
      await this.$store.dispatch('portfolio/updateSite', {
        siteId: site.id,
        data: {
          starred: !site.starred,
        },
      })
    },
    selectSite(site) {
      const selectedSite = this.userOrganization.flatMap(item => item.sites).find(orgSite => orgSite.id === site.id)

      const selectedSiteData = {
        site_integration: selectedSite.available_integrations,
        value: selectedSite.id,
        label: selectedSite.name,
        url: selectedSite.url,
        site_organization_name: this.userOrganization.find(org => org.id === selectedSite.organization_id).name,
        site_organization_id: selectedSite.organization_id,
      }
      this.loadSelectedSite(selectedSiteData)
      this.$router.push({ name: 'dashboard' })
    },
    calculateSpendPercentage(site) {
      return (site.cost.current / site.site_metric_target.spend) * 100
    },
    progressBarLabelsColor(value) {
      switch (true) {
        case value < 85 || value > 120:
          return 'text-bb-red-error'
        case value >= 95 && value <= 105:
          return 'text-bb-success-text'
        case (value >= 85 && value < 95) || (value > 105 && value <= 120):
          return 'text-bb-dark-yellow-brown'
        default:
          return 'text-bb-neutral-gray'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.w-20 {
  width: 5rem;
}
.w-25 {
  width: 6.25rem;
}

.card-item {
  @apply text-right flex flex-col justify-center items-end gap-1;
}
</style>
